.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.social-links {
  padding-top: 13px;
  z-index: 1000; 
  margin-left: 50px;
}

@media (max-width: 485px) {
  .social-links {
    padding-top: 0px;
  }
}

.icon1, .icon2 {
  color:  rgb(181, 213, 241);
}

.icon1:hover, .icon2:hover {
  color:  rgb(168, 199, 225);
}

.icon2 {
  padding-left:10px;
}

.top-header {
  display: flex;
  flex-direction: row;
}