@font-face {
  font-family: 'More Sugar';
  src: url('../assets/MoreSugar-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.name {
  font-family: 'More Sugar', sans-serif;
  display: flex;
  font-size: 50px;
  margin-bottom: 15px;
  text-align: left;
}

@media (max-width: 485px) {
  .name {
    font-size: 35px;
  }
}

.sentence-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: rgb(34, 34, 35); 
    margin-bottom: -35px;
    transition: margin 0.2s ease; 
    margin-top: -20px;
  }

  .sentence-container.open {
    margin-bottom: -25px;
  }
  
  .trigger-word, .work-trigger {
    cursor: pointer;
    color: rgb(34, 34, 35);  
    background-color: rgb(181, 213, 241);
    padding: 2px 1px;
  }
  
  .trigger-word:hover, .work-trigger:hover {
      background-color: rgb(168, 199, 225);
      transition: fill 0.2s ease;
  }
  
  .content {
    margin-top: -20px; 
    font-size: 22px;
    text-align: left;
    line-height: 1.4;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 430px;
    margin-top: 70px;
    margin-left: 10px;
    margin-right: 5px;
    /* background-size: 20px 20px;
    background-image:
      linear-gradient(to right, rgb(230, 230, 230) 1px, transparent 1px),
      linear-gradient(to bottom,  rgb(230, 230, 230) 1px, transparent 1px); */
  }

  .filter-sentence {
    display: block;
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    scale: 50%;
    transition: all 0.3s ease-in-out 0s;
  }

  .button:hover {
    scale: 52%;
    cursor: pointer;
  }

  .work-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 8px;
  }

  .work-text span.hovered {
    padding: 2px 1px;
  }

  .highlighted, .underlined {
    margin-bottom: 4px;
  }

  .highlighted {
    background-color: rgb(220, 239, 255);
    padding: 1px 1px;
  }

  .highlighted:hover {
    background-color: rgb(171, 203, 229);
    transition: fill 0.2s ease;
  }

  .underlined {
    border-bottom: 3px dashed rgb(101, 129, 153);
    color: rgb(101, 129, 153) !important;
    text-decoration: none; 
  }

  .underlined:hover {
    border-bottom: 3px dashed rgb(88, 108, 125);
    color:  rgb(88, 108, 125) !important;
  }

  .work-expand {
    margin-top: 6px;
    padding: 2px 1px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 22px;
  }
  
  .work-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .expanded-content {
    /* margin-bottom: 5px; */
    transition: margin 0.2s ease; 
    opacity: 0;
  }
  
  .expanded-content.open {
    opacity: 1;
    margin-bottom: 7px;
    max-width: 1000px;
    font-size: 22px;
    margin-right: 20px;
  }

  .filter-container {
    margin: 10px;
  }

  .hover-image {
    height: 80px;
    width: 80px;
  }

  .hover-image:hover {
    filter: brightness(0.8); 
  }